import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useParams } from 'react-router';
import { useObject, useIEnvision } from '_common/hooks';
import { useGetCurrentUserQuery } from '_common/services/api/authority';

/**
 * Assumes that an admin/owner can do everything, the remaining restrictions are:
 * @property canAccess - has access permission to the Presentation
 * @property canEdit - is allowed to edit content
 * @property canAccessVersionHistory - has access permission to the version history feature
 * @property canSaveVersion - is allowed to save a new version
 * @property canRestoreVersion - is allowed to restore a version
 * @property canShare - is allowed to share
 * @property canChangeStatus - is allowed to change the status
 * @property canComment - is allowed to create comments (comment permission)
 * @property canDeleteComment - need to be the author of specified comment
 * @property canEditComment - need to be the author of specified comment
 * @property canResolveComment - need to be the author of specified comment
 * @property canChangeCommentPriority - need to be the author of specidied comment or document owner
 * @property canEditCommentReply - need to be the author of specified comment reply
 * @property canDeleteCommentReply - need to be the author of specidied comment reply
 * @property canAccessAuditLog - is allowed to access the audit log
 * @property canAccessTasks - not integrated version, if it is, only acessable if tasks are present in document
 * @property canCreateTask - is allowed to create tasks (comment permission or document owner)
 * @property canDeleteTask - need to be the author of specified task or document owner
 * @property canChangeTaskStatus - is allowed to change the task status
 * @property canEditTask - need to be the author of specified task
 * @property canWatchTask - is allowed to watch a task
 * @property canEditTaskReply - needs to be the author of specified task reply
 * @property canDeleteTaskReply - is allowed to edit the reply of a task reply
 */
const PERMISSIONS = {
  canAccess: false,
  canEdit: false,
  canShare: false,
  canChangeStatus: false,
  canComment: false,
  canSaveVersion: false,
  canRestoreVersion: false,
  canAccessVersionHistory: false,
  canAccessAuditLog: false,
  canDeleteComment: (comment: Presentation.Data.Comment) => false,
  canEditComment: (comment: Presentation.Data.Comment) => false,
  canResolveComment: (comment: Presentation.Data.Comment) => false,
  canChangeCommentPriority: (comment: Presentation.Data.Comment) => false,
  canEditCommentReply: (reply: Presentation.Model.Comments.Reply) => false,
  canDeleteCommentReply: (reply: Presentation.Model.Comments.Reply) => false,
  canAccessTasks: false,
  canCreateTask: false,
  canDeleteTask: (task: Presentation.Data.Task) => false,
  canChangeTaskStatus: (task: Presentation.Data.Task) => false,
  canEditTask: (task: Presentation.Data.Task) => false,
  canWatchTask: false,
  canEditTaskReply: (reply: Presentation.Model.Tasks.Reply) => false,
  canDeleteTaskReply: (reply: Presentation.Model.Tasks.Reply) => false,
};

const DEFAULT_PERMISSIONS: { [Property in ApiSchemas['UserPermissionsValues']]: boolean } = {
  admin: false,
  owner: false,
  access: false,
  edit: false,
  delete: false,
  approve: false,
  comment: false,
  add_permission: false,
  remove_permission: false,
  import: false,
  export: false,
};

type ISuiteContext = {
  object: doDOC.PDF | doDOC.Document | doDOC.Presentation;
  permissions: typeof PERMISSIONS;
  app: SuiteProviderProps['app'];
  refetchObject: ReturnType<typeof useObject>['refetch'];
};

type SuiteProviderProps = {
  children: ReactNode;
  app: 'document' | 'dopdf' | 'presentation';
  /**
   * For now this is optional,
   * but when editor and pdf use the usePermissions from here, this prop should be required
   */
  hasTasks?: boolean;
};

const SuiteContext = createContext<ISuiteContext | undefined>(undefined);

const SuiteProvider = ({ children, app, hasTasks }: SuiteProviderProps) => {
  const { id } = useParams<{ id: string }>();
  const { data: object, refetch } = useObject({ object_id: id, object_type: app }, { skip: !id });
  const { data: user } = useGetCurrentUserQuery();
  const isIEnvision = useIEnvision();

  const isCommentAuthor = (comment: Presentation.Data.Comment) => {
    if (user && comment) {
      return comment.authorId === user.profile.id;
    }
    return false;
  };

  const isCommentReplyAuthor = (reply: Presentation.Model.Comments.Reply) => {
    if (user && reply) {
      return reply.authorId === user.profile.id;
    }
    return false;
  };

  const isTaskAuthor = (task: Presentation.Data.Task) => {
    if (user && task) {
      return task.authorId === user.profile.id;
    }
    return false;
  };

  const isTaskReplyAuthor = (reply: Presentation.Model.Tasks.Reply) => {
    if (user && reply) {
      return reply.authorId === user.profile.id;
    }
    return false;
  };

  const isTaskAssignee = (task: Presentation.Data.Task) => {
    if (user && task) {
      return task.assignee === user.profile.id;
    }
    return false;
  };

  const context = useMemo(() => {
    if (object && user) {
      const refetchObject = () => {
        return refetch();
      };

      const permissions = { ...PERMISSIONS };
      const userPermissions = object.user_permissions.reduce(
        (permissions, permission) => {
          permissions[permission] = true;
          return permissions;
        },
        { ...DEFAULT_PERMISSIONS },
      );

      userPermissions.admin = user.is_admin || user.is_superuser;
      // There is nothing that an admin can do that an owner cannot
      const isBossMan = userPermissions.admin || userPermissions.owner;

      const isApproved = object.status === 'approved';

      //#region General
      permissions.canAccess = isBossMan || userPermissions.access;
      permissions.canEdit = isBossMan || userPermissions.edit;
      permissions.canShare = isBossMan;
      permissions.canChangeStatus = isBossMan;
      permissions.canAccessAuditLog = isBossMan;
      //#endregion

      //#region Comments
      permissions.canComment = !isApproved && (isBossMan || userPermissions.comment);
      permissions.canDeleteComment = (comment: Presentation.Data.Comment) =>
        !isApproved && (isBossMan || (isCommentAuthor(comment) && userPermissions.comment));
      permissions.canEditComment = (comment: Presentation.Data.Comment) =>
        !isApproved && isCommentAuthor(comment) && (userPermissions.comment || isBossMan);
      permissions.canResolveComment = (comment: Presentation.Data.Comment) =>
        !isIEnvision &&
        !isApproved &&
        (isBossMan || (isCommentAuthor(comment) && userPermissions.comment));
      permissions.canChangeCommentPriority = (comment: Presentation.Data.Comment) =>
        !isApproved && (isBossMan || (isCommentAuthor(comment) && userPermissions.comment));
      permissions.canEditCommentReply = (reply: Presentation.Model.Comments.Reply) =>
        !isApproved && isCommentReplyAuthor(reply) && (userPermissions.comment || isBossMan);
      permissions.canDeleteCommentReply = (reply: Presentation.Model.Comments.Reply) =>
        !isApproved && (isBossMan || (isCommentReplyAuthor(reply) && userPermissions.comment));

      //#endregion

      //#region Tasks
      permissions.canAccessTasks = !isIEnvision || !!hasTasks;
      permissions.canCreateTask =
        !isIEnvision && !isApproved && (isBossMan || permissions.canComment);
      permissions.canDeleteTask = (task: Presentation.Data.Task) =>
        !isApproved && (isBossMan || isTaskAuthor(task));
      permissions.canEditTask = (task: Presentation.Data.Task) =>
        !isApproved && (isBossMan || isTaskAuthor(task));
      permissions.canChangeTaskStatus = (task: Presentation.Data.Task) =>
        !isApproved && (isBossMan || isTaskAuthor(task) || isTaskAssignee(task));
      permissions.canWatchTask = permissions.canAccess;
      permissions.canEditTaskReply = (reply: Presentation.Model.Tasks.Reply) =>
        !isApproved && (isBossMan || (isTaskReplyAuthor(reply) && userPermissions.comment));
      permissions.canDeleteTaskReply = (reply: Presentation.Model.Tasks.Reply) =>
        !isApproved && (isBossMan || (isTaskReplyAuthor(reply) && userPermissions.comment));
      //#endregion

      //#region Version History
      permissions.canAccessVersionHistory = isBossMan;
      permissions.canSaveVersion = !isApproved && isBossMan;
      permissions.canRestoreVersion = !isApproved && isBossMan;
      //#endregion
      return { permissions, object, app, refetchObject };
    }
  }, [object, user, refetch]);

  if (context) {
    return <SuiteContext.Provider value={context}>{children}</SuiteContext.Provider>;
  }
  return null;
};

const useSuite = () => {
  const context = useContext(SuiteContext);
  if (context === undefined) {
    throw new Error('useSuiteObject must be used within a SuiteProvider');
  }
  return context;
};

export const useSuiteApp = () => {
  return useSuite().app;
};

export const useSuiteObject = () => {
  return useSuite().object;
};

export const useSuitePermissions = () => {
  return useSuite().permissions;
};

export const useSuiteUtils = () => {
  const context = useSuite();
  return { refetchObject: context.refetchObject };
};

// export const use;

export default SuiteProvider;
